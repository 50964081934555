import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  HostBinding,
} from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  ContainsValidationItem,
  ValidationItemParameter,
} from "src/app/models";
import { ChildFormBase, CHILD_FORM_TOKEN } from "../../directives";

const MAX_PARAMETER_COUNT = 9;

@Component({
  selector: "app-contains-validation-item",
  templateUrl: "./contains-validation-item.component.html",
  styleUrls: ["./contains-validation-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: CHILD_FORM_TOKEN,
      useExisting: forwardRef(() => ContainsValidationItemComponent),
    },
  ],
})
export class ContainsValidationItemComponent extends ChildFormBase {
  constructor(private fb: FormBuilder, private cdr: ChangeDetectorRef) {
    super();
  }

  @HostBinding("class") class = "app-contains-validation-item";

  _form: FormGroup = this.fb.group({
    instanceCount: this.fb.control(1, [
      Validators.required,
      Validators.min(1),
      Validators.max(7),
    ]),
    functionName: this.fb.control("", Validators.required),
    parameters: this.fb.array([]),
  });

  get childForm(): FormGroup {
    return this._form;
  }

  get _parametersFormArray(): FormArray {
    return this._form.get("parameters") as FormArray;
  }

  get _allowAddParameter(): boolean {
    return this._parametersFormArray.length < MAX_PARAMETER_COUNT;
  }

  setChildForm(item: ContainsValidationItem): void {
    this._form.patchValue(item);
    item?.parameters?.forEach((p) => this.addParameter(p));
  }

  removeParameterAt(index: number): void {
    this._parametersFormArray.removeAt(index);
    this._parametersFormArray.markAsDirty();
    this.cdr.markForCheck();
  }

  addParameter(parameter: ValidationItemParameter = null): void {
    if (this._parametersFormArray.length === MAX_PARAMETER_COUNT) {
      return;
    }

    this._parametersFormArray.push(
      this.fb.control(parameter || { operator: "gte" })
    );
    this._parametersFormArray.markAsDirty();
    this.cdr.markForCheck();
  }
}
