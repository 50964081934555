<div
  [formGroup]="_form"
  fxLayout
  fxLayoutAlign="center center"
  fxLayoutGap="4px">
  <mat-form-field
    floatLabel="never"
    [class.operator]="
      selectedOperator !== 'ignore' &&
      selectedOperator !== 'contains' &&
      selectedOperator !== 'inclusive'
    "
    [class.operator-wider]="
      selectedOperator == 'contains' || selectedOperator == 'inclusive'
    ">
    <span matPrefix><ng-content></ng-content>&nbsp;</span>
    <mat-select formControlName="operator">
      <mat-select-trigger [class.underline]="shouldUnderline(selectedOperator)">
        {{ operatorText }}
      </mat-select-trigger>
      <mat-option
        *ngFor="let op of operations | keyvalue : _originalOrder"
        [value]="op.key">
        <div [class.underline]="shouldUnderline(op.key)">
          {{ op.value }}
        </div>
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field
    *ngIf="selectedOperator !== 'ignore' && selectedOperator !== 'inclusive'">
    <input matInput formControlName="value" />
  </mat-form-field>

  <ng-container *ngIf="selectedOperator === 'inclusive'">
    <mat-form-field class="inclusiveInput">
      <input matInput formControlName="minValue" />
    </mat-form-field>
    <mat-form-field class="inclusiveInput">
      <input matInput formControlName="maxValue" />
    </mat-form-field>
  </ng-container>
</div>
